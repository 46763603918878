/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/theming'; // Plus imports for other components in your app.
// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat-core(); // define a real custom palette (using http://mcg.mbitson.com)
$primary-color: (
  50 : #e4eaf4,
  100 : #bdcbe3,
  200 : #91a8d0,
  300 : #6485bd,
  400 : #436aae,
  500 : #2250a0,
  600 : #1e4998,
  700 : #19408e,
  800 : #143784,
  900 : #0c2773,
  A100 : #a5b9ff,
  A200 : #7291ff,
  A400 : #3f69ff,
  A700 : #2555ff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

$secondary-color: (
  50 : #e0e0e0,
  100 : #b3b3b3,
  200 : #808080,
  300 : #4d4d4d,
  400 : #262626,
  500 : #000000,
  600 : #000000,
  700 : #000000,
  800 : #000000,
  900 : #000000,
  A100 : #a6a6a6,
  A200 : #8c8c8c,
  A400 : #737373,
  A700 : #666666,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$custom-primary: mat-palette($primary-color);
$custom-accent: mat-palette($secondary-color); // The warn palette is optional (defaults to red).
$custom-warn: mat-palette($mat-red); // Create the theme object (a Sass map containing all of the palettes).
$custom-theme: mat-light-theme($custom-primary, $custom-accent, $custom-warn); // Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($custom-theme);

.background-primary {
  background: mat-color($custom-primary);
}

.color-primary {
  color: mat-color($custom-primary);
}

.background-accent {
  background: mat-color($custom-accent);
}

.warning-message {
  background: mat-color($custom-warn);
}

.third-color {
  color: #f0284a;
}

.third-background {
  background: black;
}